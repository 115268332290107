import React from "react"
import Seo from "../components/seo"
import "../styles/theme.scss"
import Layout from "../layouts/layout";
import { graphql } from "gatsby"
import Videos from "../components/videos";

export default class Article extends React.Component {
    render() {
        let global = this.props.data.contentfulWebsite;
        let episodes = this.props.data.allContentfulVideos.nodes;
        return (
            <Layout pathname={this.props.location.pathname}>
                <Seo metaTitle={`Videos - ${global.title}`} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
                <div className="o-wrapper o-content o-container">
                    <div className="o-wrapper o-wrapper--small">
                        {episodes.length > 0 ? <Videos episodes={episodes}></Videos> : ''}
                    </div>
                </div>
            </Layout>
        );
    };
}

export const pageQuery = graphql`
{
    contentfulWebsite(title: {eq: "Black Ice"}) {
        author
        description
        title
        videoCtaText
        shareImage {
            file {
                url
            }
        }
    }
    allContentfulVideos {
        nodes {
            name
            description {
                raw
            }
            thumbnail {
                resize(width: 1080) {
                    src
                }
            }
            youTubeLink
        }
    }
}
`;
